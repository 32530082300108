'use client';

import React, { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { Button } from '@shadcn/components/ui/button';
import { Checkbox } from '@shadcn/components/ui/checkbox';

import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@shadcn/components/ui/form';

import { FormInput } from '@shadcn/components/ui/input';
import { useLocale, useTranslations } from 'next-intl';
import { useAuth } from '../../_providers/Auth';
import { errorTranslations } from '../../_utilities/api-translations';

export const RecoverPasswordForm: React.FC<{
	afterSubmit: () => unknown;
}> = props => {
	const { afterSubmit } = props;
	const t = useTranslations('Auth');
	const locale = useLocale();
	const [globalError, setGlobalError] = useState<null | string>(null);
	const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);
	const { forgotPassword } = useAuth();

	const formSchema = z.object({
		email: z.coerce
			.string()
			.min(1, {
				message: t('email-is-missing'),
			})
			.email({
				message: t('invalid-email'),
			}),
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: '',
		},
	});

	async function onSubmit(values: z.infer<typeof formSchema>) {
		setSubmitDisabled(true);
		try {
			await forgotPassword(values);
			afterSubmit();
		} catch (err) {
			// @ts-expect-error TODO error typings
			if (Array.isArray(err?.errors)) {
				// @ts-expect-error TODO error typings
				err?.errors.forEach(error => {
					if (Array.isArray(error?.data)) {
						// @ts-expect-error TODO error typings
						error?.data.forEach(({ field, message }) => {
							form.setError(field, {
								// @ts-expect-error TODO error typings
								message: errorTranslations[message]?.[locale] || message,
							});
						});
					} else {
						if (error?.message) {
							setGlobalError(error.message);
						} else {
							setGlobalError(t('there-was-an-unexpected-error-please-try-again'));
						}
					}
				});
			} else {
				setGlobalError(t('there-was-an-unexpected-error-please-try-again'));
			}
		}
		setSubmitDisabled(false);
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="">
				<FormField
					control={form.control}
					name="email"
					render={({ field }) => (
						<FormItem className="mb-1">
							<FormLabel aria-required>{t('email')}</FormLabel>
							<FormControl>
								<FormInput placeholder={t('email')} type="email" {...field} />
							</FormControl>
							<p className="ml-4 text-sm font-medium italic">{t('ifEmailExists')}</p>
							<FormMessage>&nbsp;</FormMessage>
						</FormItem>
					)}
				/>

				<Button type="submit" disabled={isSubmitDisabled} className="">
					{t('sendRecoverPasswordLink')}
				</Button>
				{globalError ? (
					<p className="text-error">{globalError}</p>
				) : (
					<p className="text-error">&nbsp;</p>
				)}
			</form>
		</Form>
	);
};
