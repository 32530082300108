'use client';

import React, { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { Button } from '@shadcn/components/ui/button';
import { Checkbox } from '@shadcn/components/ui/checkbox';

import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@shadcn/components/ui/form';

import { FormInput } from '@shadcn/components/ui/input';
import { useLocale, useTranslations } from 'next-intl';
import { useAuth } from '../../_providers/Auth';
import { errorTranslations } from '../../_utilities/api-translations';
import { Link } from '@/navigation';

export const RegisterForm: React.FC<{
	afterSubmit: () => unknown;
}> = props => {
	const { afterSubmit } = props;
	const t = useTranslations('Auth');
	const locale = useLocale();
	const [globalError, setGlobalError] = useState<null | string>(null);
	const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false);

	const { create } = useAuth();
	const formSchema = z
		.object({
			firstName: z.string().min(1, {
				message: t('firstNameRequired'),
			}),
			lastName: z.string().min(1, {
				message: t('lastNameRequired'),
			}),
			email: z.coerce
				.string()
				.min(1, {
					message: t('email-is-missing'),
				})
				.email({
					message: t('invalid-email'),
				}),

			password: z.string().min(1, {
				message: t('password-is-required'),
			}),
			passwordConfirm: z.string().min(1, {
				message: t('password-is-required'),
			}),
			acceptTerms: z.literal(true, {
				errorMap: () => ({ message: t('termsRequired') }),
			}),
		})
		.superRefine(({ password, passwordConfirm }, ctx) => {
			if (password !== passwordConfirm) {
				ctx.addIssue({
					code: 'custom',
					message: t('the-passwords-did-not-match'),
					path: ['passwordConfirm'],
				});
			}
		});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
			passwordConfirm: '',
		},
	});

	async function onSubmit(values: z.infer<typeof formSchema>) {
		setSubmitDisabled(true);
		try {
			await create(values);
			afterSubmit();
		} catch (err) {
			// @ts-expect-error TODO error typings
			if (Array.isArray(err?.errors)) {
				// @ts-expect-error TODO error typings
				err?.errors.forEach(error => {
					if (Array.isArray(error?.data)) {
						// @ts-expect-error TODO error typings
						error?.data.forEach(({ field, message }) => {
							form.setError(field, {
								// @ts-expect-error TODO error typings
								message: errorTranslations[message]?.[locale] || message,
							});
						});
					} else {
						if (error?.message) {
							setGlobalError(error.message);
						} else {
							setGlobalError(t('there-was-an-unexpected-error-please-try-again'));
						}
					}
				});
			} else {
				setGlobalError(t('there-was-an-unexpected-error-please-try-again'));
			}
		}
		setSubmitDisabled(false);
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="">
				<FormField
					control={form.control}
					name="firstName"
					render={({ field }) => (
						<FormItem className="mb-1">
							<FormLabel aria-required>{t('firstName')}</FormLabel>
							<FormControl>
								<FormInput placeholder={t('firstName')} type="text" {...field} />
							</FormControl>

							<FormMessage>&nbsp;</FormMessage>
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="lastName"
					render={({ field }) => (
						<FormItem className="mb-1">
							<FormLabel aria-required>{t('lastName')}</FormLabel>
							<FormControl>
								<FormInput placeholder={t('lastName')} type="text" {...field} />
							</FormControl>

							<FormMessage>&nbsp;</FormMessage>
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="email"
					render={({ field }) => (
						<FormItem className="mb-1">
							<FormLabel aria-required>{t('email')}</FormLabel>
							<FormControl>
								<FormInput placeholder={t('email')} type="email" {...field} />
							</FormControl>

							<FormMessage>&nbsp;</FormMessage>
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="password"
					render={({ field }) => (
						<FormItem className="mb-1">
							<FormLabel aria-required>{t('password')}</FormLabel>
							<FormControl>
								<FormInput type="password" placeholder={t('password')} {...field} />
							</FormControl>
							<FormMessage>&nbsp;</FormMessage>
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="passwordConfirm"
					render={({ field }) => (
						<FormItem className="mb-1">
							<FormLabel aria-required>{t('passwordConfirm')}</FormLabel>
							<FormControl>
								<FormInput
									type="password"
									placeholder={t('passwordConfirm')}
									{...field}
								/>
							</FormControl>
							<FormMessage>&nbsp;</FormMessage>
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="acceptTerms"
					render={({ field }) => (
						<FormItem className="mb-10 space-y-0">
							<div className="flex flex-row items-center">
								<FormControl>
									<Checkbox
										checked={field.value}
										onCheckedChange={field.onChange}
									/>
								</FormControl>
								<FormLabel className="text-base font-normal">
									{t('accept')}&nbsp;
									<Link
										className="link-styles"
										href="/documents/terms-of-use"
										target="_blank"
									>
										{t('terms')}
									</Link>
								</FormLabel>
							</div>

							<FormMessage>&nbsp;</FormMessage>
						</FormItem>
					)}
				/>

				<Button type="submit" disabled={isSubmitDisabled} className="">
					{t('createAccount')}
				</Button>
				{globalError ? (
					<p className="text-error">{globalError}</p>
				) : (
					<p className="text-error">&nbsp;</p>
				)}
			</form>
		</Form>
	);
};
