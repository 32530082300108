'use client';

import React, { Fragment, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { Button } from '@shadcn/components/ui/button';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@shadcn/components/ui/form';

import { FormInput } from '@shadcn/components/ui/input';
import { useTranslations, useLocale } from 'next-intl';
import { useAuth } from '../../_providers/Auth';
import { errorTranslations } from '../../_utilities/api-translations';

export const LoginForm: React.FC<{
	afterSubmit: () => unknown;
}> = props => {
	const { afterSubmit } = props;
	const t = useTranslations('Auth');
	const locale = useLocale();
	const formSchema = z
		.object({
			email: z.coerce
				.string()
				.min(1, {
					message: t('email-is-missing'),
				})
				.email({
					message: t('invalid-email'),
				}),
			password: z.string().min(1, {
				message: t('password-is-required'),
			}),
		})
		.required();
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: '',
			password: '',
		},
	});
	const [globalError, setGlobalError] = useState<null | string>(null);
	const { login } = useAuth();

	async function onSubmit(values: z.infer<typeof formSchema>) {
		try {
			await login(values);
			afterSubmit();
		} catch (err) {
			// @ts-expect-error TODO error typings
			if (Array.isArray(err?.errors)) {
				// @ts-expect-error TODO error typings
				err?.errors.forEach(error => {
					if (Array.isArray(error?.data)) {
						// @ts-expect-error TODO error typings
						error?.data.forEach(({ field, message }) => {
							form.setError(field, {
								// @ts-expect-error TODO error typings
								message: errorTranslations[message]?.[locale] || message,
							});
						});
					} else {
						if (error?.message) {
							setGlobalError(error.message);
						} else {
							setGlobalError(t('there-was-an-unexpected-error-please-try-again'));
						}
					}
				});
			} else {
				setGlobalError(t('there-was-an-unexpected-error-please-try-again'));
			}
		}
	}

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
				<FormField
					control={form.control}
					name="email"
					render={({ field }) => (
						<FormItem>
							<FormLabel aria-required>{t('email')}</FormLabel>
							<FormControl>
								<FormInput placeholder={t('email')} type="email" {...field} />
							</FormControl>

							<FormMessage>&nbsp;</FormMessage>
						</FormItem>
					)}
				/>
				<FormField
					control={form.control}
					name="password"
					render={({ field }) => (
						<FormItem>
							<FormLabel aria-required>{t('password')}</FormLabel>
							<FormControl>
								<FormInput type="password" placeholder={t('password')} {...field} />
							</FormControl>
							<FormMessage>&nbsp;</FormMessage>
						</FormItem>
					)}
				/>

				<Button type="submit">{t('loginAction')}</Button>
				{globalError ? (
					<p className="text-error">{globalError}</p>
				) : (
					<p className="text-error">&nbsp;</p>
				)}
			</form>
		</Form>
	);
};
